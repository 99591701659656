import { Api } from "@/models/class/api.class";
import { RequestSalesOrderCreate, RequestSalesOrderUpdate, RequestSalesOrderWarehouseUpdate, ResponseDetailSalesOrder, ResponseListSalesOrder, ResponseSubmitSalesOrder } from "@interface/sales-order.interface";
import { ErrorResponseModel, RequestQueryParamsModel } from "@/models/interface/http.interface";
import { HttpClient } from "@/services/http.service";
import { AxiosError } from "axios";

export class SalesOrderServices extends HttpClient {
  constructor() {
    super();
  }

  getList(params: RequestQueryParamsModel): Promise<ResponseListSalesOrder> {
    return this.get<ResponseListSalesOrder>(Api.SalesOrder, { params })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  /**
   * create new sales order as draft
   * @param payload request create
   * @returns response detail sales order
   */
  createSalesOrder(payload: RequestSalesOrderCreate): Promise<ResponseDetailSalesOrder> {
    return this.post<ResponseDetailSalesOrder, RequestSalesOrderCreate>(Api.SalesOrder, payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  getDetailSalesOrder(id: string): Promise<ResponseDetailSalesOrder> {
    return this.get<ResponseDetailSalesOrder>(`${Api.SalesOrder}/${id}`)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  /**
   * update data sales order
   * @param id sales order id
   * @param payload request update
   * @returns detail sales order
   */
  updateSalesOrder(id: string, payload: RequestSalesOrderUpdate): Promise<ResponseDetailSalesOrder> {
    return this.put<ResponseDetailSalesOrder, RequestSalesOrderUpdate>(`${Api.SalesOrder}/${id}`, payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  cancelSalesOrder(id: string): Promise<boolean> {
    return this.put<boolean, string>(`${Api.SalesOrder}/cancel/${id}`, null)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  /**
   * used by delivery order
   * @param id sales order id
   * @returns sales order detail
   */
  getDetailOutstandingSalesOrder(id: string): Promise<ResponseDetailSalesOrder> {
    return this.get<ResponseDetailSalesOrder>(`${Api.SalesOrder}/outstanding/${id}`)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  submitSalesOrder(id: string, payload: RequestSalesOrderUpdate): Promise<ResponseSubmitSalesOrder> {
    return this.put<ResponseSubmitSalesOrder, string>(`${Api.SalesOrder}/submit/warehouse/${id}`, payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  approveSalesOrder(id: string): Promise<ResponseSubmitSalesOrder> {
    return this.put<ResponseSubmitSalesOrder, string>(`${Api.SalesOrder}/approve/${id}`, null)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  rejectSalesOrder(id: string): Promise<ResponseSubmitSalesOrder> {
    return this.put<ResponseSubmitSalesOrder, string>(`${Api.SalesOrder}/reject/${id}`, null)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  updateSalesOrderWarehouse(id: string, payload: RequestSalesOrderWarehouseUpdate): Promise<ResponseSubmitSalesOrder> {
    return this.put<ResponseSubmitSalesOrder, string>(`${Api.SalesOrder}/submit/${id}`, payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

}

export const salesOrderService = new SalesOrderServices();
