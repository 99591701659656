






























































































import MNotificationVue from "@/mixins/MNotification.vue";
import { ResponseListSalesOrder, ResponseSalesOrder } from "@interface/sales-order.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { salesOrderService } from "@service/sales-order.service";
import { IOption } from "@interface/common.interface";
import Vue from "vue";
import { ResponsePagination } from "@/models/interface/common.interface";
import { SALES_ORDER_STATUS } from "@/models/enums/sales-order.enum";
import { trimSpaceToUnderscore } from "@/helpers/common";

interface Row extends ResponseSalesOrder {
  key: any;
}

export default Vue.extend({
  name: "CSelectMultiSalesOrder",
  mixins: [
    MNotificationVue,
  ],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    propCustomerId: {
      type: String,
      default: undefined,
    }
  },
  data() {
    return {
      dtOpt: [] as IOption<ResponseSalesOrder>[],
      loading: false,
      dtTable: [] as Row[],
      selectedRowKeys: [] as string[],
      dtSalesOrder: {} as ResponseListSalesOrder,
      queryParams: {
        limit: 20,
        page: 0,
        sorts: "createdDate:desc",
        search: `state~${trimSpaceToUnderscore(SALES_ORDER_STATUS.SUBMITTED)}_OR_state~${trimSpaceToUnderscore(SALES_ORDER_STATUS.PARTIAL_DELIVERED)}`
      } as RequestQueryParamsModel,
      selectedSO: [] as ResponseSalesOrder[]
    };
  },
  watch: {
    propCustomerId(newValue) {
      if (newValue) {
        this.selectedSO = [];
        this.queryParams.search = `state~${trimSpaceToUnderscore(SALES_ORDER_STATUS.SUBMITTED)}_OR_state~${trimSpaceToUnderscore(SALES_ORDER_STATUS.PARTIAL_DELIVERED)}_AND_customer.secureId~${this.propCustomerId}`;
        this.getList(this.queryParams);
      }
    }
  },
  created() {
    if (this.propCustomerId) {
      this.queryParams.search = `state~${trimSpaceToUnderscore(SALES_ORDER_STATUS.SUBMITTED)}_OR_state~${trimSpaceToUnderscore(SALES_ORDER_STATUS.PARTIAL_DELIVERED)}_AND_customer.secureId~${this.propCustomerId}`;
    }
    this.getList(this.queryParams);
  },
  methods: {
    getListSalesOrder(params: RequestQueryParamsModel): Promise<ResponseListSalesOrder> {
      return salesOrderService.getList(params);
    },
    handleCancel(): void {
      this.$emit("input", false);
    },
    handleSave(): void {
      this.$emit("on-save", this.selectedSO);
      this.$emit("input", false);
    },
    onRowSelect(record: Row, selected: boolean): void {
      const { selectedSO } = this;
      if (selected) {
        this.selectedSO = [...selectedSO, record];
      } else {
        this.selectedSO = selectedSO.filter(x => x.id !== record.id);
      }
    },
    onAllRowSelect(selected: boolean, selectedRows: Row[], changeRows: Row[]): void {
      const { selectedSO } = this;
      if (selected) {
        this.selectedSO = [...selectedSO, ...selectedRows];
      } else {
        const ids = changeRows.map(x => x.id);
        this.selectedSO = selectedSO.filter(x => !ids.includes(x.id));
      }
    },
    onRowChange(selectedRowKeys: string[]): void {
      this.selectedRowKeys = selectedRowKeys;
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.queryParams.limit = response.size;
      this.queryParams.page = 0;
      this.getList(this.queryParams);
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.queryParams.page = response.page - 1;
      this.getList(this.queryParams);
    },
    async getList(params: RequestQueryParamsModel): Promise<void> {
      try {
        this.loading = true;
        this.dtSalesOrder = await this.getListSalesOrder(params);
        this.dtTable = this.dtSalesOrder.data.map((x, i) => ({
          key: x.id,
          ...x
        }));
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading = false;
      }
    },
  }
});
