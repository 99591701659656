export enum SALES_ORDER_STATUS {
  BILLED = "Billed",
  CANCELLED = "Cancelled",
  DELIVERED = "Delivered",
  DRAFT = "Draft",
  INVOICING = "Invoicing",
  PICKED = "Picked",
  RETURNED = "Returned",
  SUBMITTED = "Submitted",
  PARTIAL_DELIVERED = "Partial Delivered",
  WAIT_FOR_APPROVAL = "Wait For Approval",
  WAITING_FOR_WAREHOUSE = "Wait For Warehouse",
}
